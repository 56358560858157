.app {
  background-color: white;
  width: 100vw;
  min-height: 100vh;
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

p {
  line-height: 2;
  font-size: 1rem;
  color: rgb(69, 69, 69);
}
h1,
h2,
h3,
h4 {
  color: rgb(69, 69, 69);
}

label {
  color: rgb(69, 69, 69);
  font-weight: bold;
  font-size: 1.2rem;
}

body {
  margin: 0;
  padding: 0;
}
