.main-image {
  z-index: -5;
  height: 25rem;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgba(128, 128, 128, 0.137);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  
  
}
.inner_content{
  /* z-index: 0;
  position: absolute; */
  width: 100%;
  height: 100%;
  
}

.main-image img {
  /* z-index: -5;
  width: 100%;
  height: 100%;
  object-fit: cover;
  */
  
  /*transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem); */
}