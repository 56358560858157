.contact_main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}
.title {
  width: 90%;
}

.contact_main form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  
  border: 1px solid rgba(0, 0, 0, 0.195);
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding: 3rem;
  background-color: aliceblue;
  margin-bottom: 1rem;
  box-shadow: 4px 15px 25px rgba(128, 128, 128, 0.446);
  border-radius: 10px;
}
.contact_main label {
  text-align: left;
}

.dropdown{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
.dropdown label{
  margin-bottom: .5rem;
}
.dropdown select {
  padding: 5px;
  border: 1px solid #dedede;
  border-radius: 8px;
}

.submit{
  border-radius: 24px;
  background-color: rgb(255, 255, 255);
  width: 5rem;
  height: 2rem;
  border: 1px solid #dedede;
  transition: all 0.1s;
  color: rgb(69, 69, 69);
}
.submit:hover{
  cursor: pointer;
  background-color: rgb(234, 234, 234);
  transition: all 0.1s ;
}

@media screen and (max-width: 1100px) {
  .contact_main form{
    width: 80%;
    
    
  }
}
@media screen and (max-width: 500px) {
  .contact_main form {
    width: 75%;
    padding: 1.5rem;
  }
  .contact_main label {
    font-size: .85rem;
  }
}



