.spButton {
  border-radius: 24px;

  background-color: white;
  color: rgb(69, 69, 69);
  width: 5rem;
  border: 1px solid rgba(69, 69, 69, 0.588);
  box-shadow: 2px 5px 12px rgba(0, 0, 0, 0.329);
  padding: .5rem;
  transition: all 0.1s;

}

.spButton:hover {
  cursor: pointer;
  background-color: rgb(175, 175, 175);
  transition: all 0.1s;
}