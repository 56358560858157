.small {
  display: block;
  margin-bottom: 0em;
}

.medium {
  display: block;
  margin-bottom: 2em;
}

.large {
  display: block;
  margin-bottom: 3em;
}