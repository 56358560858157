.modal_main{
  position: fixed;
  top: 50%;
  left: 50%;
  
  transform: translate(-50%, -50%);
  background-color: rgb(245, 245, 245);
  
  padding: 1.5rem;
  z-index: 1000;
  border: 1px solid black;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.617);
  border-radius: 24px;
  background-image: url('../assets/bg00-header.jpg');
  background-size: cover;

}
.modal_main button{
  border-radius: 24px;
  
  background-color: white;
  color: rgb(69, 69, 69);
  width: 5rem;
  border: 1px solid rgba(69, 69, 69, 0.588);
  box-shadow: 2px 5px 12px rgba(0, 0, 0, 0.329);
  padding: .5rem;
  transition: all 0.1s;
}
.modal_main button:hover{
  cursor: pointer;
  background-color: rgb(175, 175, 175);
  transition: all 0.1s;
}
.modal_content p {
  margin-bottom: 1rem;
}
.modal_content h2{
  margin: 0
}

.modal_backdrop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1000;
}
.modal_content{
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .modal_main {
    width: 60%;
  }
  .modal_content p {
    font-size: 1rem;
  }
  .modal_main button{
    width: 100%;
  }

}