.textarea_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.textarea_section label{
  text-align: left;
  margin-bottom: .5rem;
}

.textarea_section textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  
  padding: 5px;
  border: 1px solid #dedede;
  border-radius: 8px;
}
