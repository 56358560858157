.input_f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1rem;

}
.input_f label{
  margin-bottom: .5rem;
}

.input_f input{
  border-radius: 8px;
  padding: 4px;
  width: 100%;

  height: 27px;
  
  border: 1px solid #dedede;
  
}
