.navlinks_holder {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 200;
  top: 0;
  width: 100%;
  background-color: white;
  border-bottom: 0.1px solid rgba(194, 194, 194, 0.399);
  box-shadow: 1px 0.1px 10px rgba(184, 184, 184, 0.655);
  transition: opacity 0.3s ease;
  opacity: 1; /* Adjust the opacity as needed */
}
.nav_faded {
  transition: opacity 0.3s ease;
  opacity: 0; /* Adjust the opacity as needed */
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  height: 6rem;
}
.mobile_menu {
  display: none;
  background: none;
  border: none;
}
.logo {
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: none;

  color: black;
}
.logo img {
  width: 6rem;
}
.navlinks {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  list-style: none;
  overflow: hidden;
}

.navlinks li {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 984px) {
  .mobile_menu {
    display: flex;
  }
  .logo {
    display: flex;

    align-items: center;
    top: 15px;
    left: 35px;
  }
  .navlinks {
    display: none;
  }
  .navlinks_mobile {
    display: flex;
    position: absolute;
    list-style: none;
    background-color: whitesmoke;
    top: 80px;
    flex-direction: column;
    padding: 2rem;
    transition: all 0.5s ease;
    width: 100%;
    align-items: center;
  }
  .navlinks_mobile li {
    padding: 1rem;
    width: 100%;
    text-align: center;
    transition: all 0.5s ease;
  }
}

.link_inactive:hover {
  text-decoration: underline;
  text-decoration-color: rgb(0, 0, 0);
  text-underline-offset: 10px;

  transition: text-decoration-color 250ms linear;
}

.link_inactive {
  text-decoration-color: rgba(255, 255, 255, 0);
  text-underline-offset: 10px;
  color: rgb(98, 98, 98);
  transition: all 250ms linear;
}

.link_active {
  text-decoration-color: rgb(0, 0, 0);
  text-underline-offset: 10px;
  color: rgb(0, 0, 0);
  transition: color 250ms linear;
}
