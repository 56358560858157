.services_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services_container p {
  width: 50%;
}
.services_container h1 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.fees_assessments {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}
.fees_title_div {
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.fees_title_div h1,
h2 {
  padding: 0;
  margin: 0;
}
.fees_title_div h2 {
  font-size: 1.3rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

@media screen and (max-width: 983px) {
  .services_container h1 {
    font-size: 1.6rem;
  }

  .services_container p {
    width: 85%;
  }
}
