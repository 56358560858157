.main{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.main p {
  width: 100%;
}


@media screen and (max-width: 983px) {
  .main {
    width: 85%;
  }
  .main h3 {
    font-size: 1rem;
  }
}