.assess_title {
  text-align: center;
  margin-bottom: 0;
}

.game_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  margin-left: 10%;
  margin-right: 10%;
}

.game_container section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  margin-bottom: 1rem;
}

.game_container section p {
  margin-top: 0;
  margin-bottom: .5rem;
}


.game_title {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.game_title h1 {
  margin: 0;
  padding: 0;
}

.game_title button {
  width: 8rem;

  margin-left: 1rem;
  max-height: 3rem;
}


.game {
  border-radius: 20px;
  box-shadow: 2px 7px 24px rgba(0, 0, 0, 0.76);
}




.loading_overlay.p {}