.info_main{
  display: flex;
  flex-direction: column;
  
  align-items: center;
  
  color: rgb(69, 69, 69);
  
  text-align: center;
  margin-top: 1rem;
  width: 20rem;
}

.info_main_img{
  
  width: 40%;
}

.info_main_desc {
  inline-size: 20rem;
  margin-top: -.5rem;
}

@media screen and (max-width: 1443px) {
  .info_main{
    width: 15rem;
  }
  .info_main_desc {
    

    inline-size: 15rem;
  }


}

@media screen and (max-width: 1133px) {
  .info_main {
      width: 50%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  
    .info_main_desc {
      width: 100%;
    }
}
@media screen and (max-width: 900px) {
  .info_main {
    width: 80%;
  }

  .info_main_desc {
    width: 100%;
  }
}