.image{
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1/1;
  margin-bottom: -4rem;
  margin-top: -3rem;
}
.image_container{
  display: flex;
  justify-content: center;
  margin: auto;
  width: 80%;

}
.desc{
  text-align: center;
  
}

.link {
  text-underline-offset: 10px;
  text-decoration-line: none;
  text-decoration-color: rgb(69, 69, 69);
  transition: all 0.5s ease-in-out;
  
}
.link:hover{
  text-decoration-line: underline;
  text-decoration-color: rgb(69, 69, 69);
  transition: all 0.5s ease-in-out;
}