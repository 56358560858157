.header_main{
  height: 1rem;
}

.about_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  
}
.about_container h1 {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  width: auto;
    height: auto;
}
.image_container{
  /* padding: 2rem;
  border-radius: 25px;
  box-shadow: 4px 8px 12px gray; */
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  
}

.image_container img {
  width: 250px;
  margin-right: 1rem;
  float: left;
}
.image_container p {
  inline-size: 55rem;
  margin-top: 0;
}



@media screen and (max-width: 910px) {
  .image_container p {
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    width: 90%;
  }
  .image_container img {
    margin-left: 2.25rem;
    width: 35%;
    margin-right: 1rem;
    float: left;
  }
}
@media screen and (max-width: 580px) {
  .about_container h1 {
    font-size: 1.5rem;
    
  }
}
@media screen and (max-width: 546px) {
  .image_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .image_container p {
    
    width: 100%;
  }
  .image_container img {
    width: 100%;
    margin: 0;
  }

}