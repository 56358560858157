.footer_main {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: rgb(69, 69, 69);
  min-height: 15rem;

  bottom: 0;

  left: 0;
  right: 0;
  padding-bottom: 0px;
  margin-top: auto;
  text-align: center;
}

.footer_main img {
  width: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.contact_info {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  align-items: center;
}

.contact_info h1 {
  color: rgb(206, 206, 206);
  margin: 0;
}

.contact_info h4 {
  color: rgb(206, 206, 206);
  margin: 0.3rem;
}
