.container{
  margin: 1rem;
}
.header_content_holder{ 
  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  color: rgb(69, 69, 69);
}

.header_content_holder h1{
  font-size: 5rem;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}



.header_content_holder button {
  cursor: pointer;
  background-color: whitesmoke;
  border: none;
  border-radius: 24px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 1px 5px 12px gray;
  transition: all 0.5s ease-in-out;
}
.header_content_holder button:hover {
  background-color: rgb(198, 198, 198);
  transition: all .1s ease-in-out;
}
.info2{
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  overflow: hidden;
  gap: 2rem;
  
  
}




.trust_desc {
  inline-size: 70rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}



.trust {
  overflow: hidden;
  display: flex;
  justify-content: center;
  font: inherit;
  color: rgb(69, 69, 69);
  
}
.trust_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
}


.services_link{
  text-decoration: none;
}
.services_link h2:hover {
  color: black;
  text-decoration-line: underline;
  text-underline-offset: 10px;
  transition: all 0.5s ease-in-out;
}
.services_link h3 {
  color: rgb(69, 69, 69);
  font-style: italic;
}
.imageinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.line{
  width: 75%;
  border: .5px solid rgba(128, 128, 128, 0.108);
  
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1120px) {
  .trust_desc {
    /* inline-size: 40rem; */
    width: 90%;
  }

}

@media screen and (max-width: 400px) {
  .header_content_holder h1 {
    font-size: 4rem;
    width: 90%;
  }
}
@media screen and (max-width: 250px) {
  .header_content_holder h1 {
    font-size: 2rem;

  }
}

@media screen and (max-width: 900px){
  .info2 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      
  
  
    }
}